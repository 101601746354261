const USER_LANGUAGE_KEY = 'userLanguage';

export enum Language {
  ENGLISH = 'EN',
  FRENCH = 'FR',
  GERMAN = 'DE',
  ITALIAN = 'IT',
  SPANISH = 'ES',
  ARABIC = 'AR',
}

export interface SelectLanguageOption {
  label: string;
  value: Language;
}

export const languageOptions: SelectLanguageOption[] = [
  { value: Language.ENGLISH, label: 'English' },
  { value: Language.FRENCH, label: 'French' },
  { value: Language.GERMAN, label: 'German' },
  { value: Language.ITALIAN, label: 'Italian' },
  { value: Language.SPANISH, label: 'Spanish' },
  { value: Language.ARABIC, label: 'Arabic' },
];

export const findSupportedLanguage = (navigatorLanguage: string): Language => {
  const langIso639Code = navigatorLanguage.split('-')[0];
  for (const item of languageOptions) {
    if (item.value == langIso639Code.toUpperCase()) {
      return item.value;
    }
  }
  return Language.ENGLISH;
};

export const saveUserLanguageToLocalStorage = (language: Language) => {
  localStorage.setItem(USER_LANGUAGE_KEY, String(language));
};

export const getUserLanguageFromLocalStorage = (
  defaultLanguage: Language = Language.ENGLISH
): Language => {
  const userLanguage = localStorage.getItem(USER_LANGUAGE_KEY);
  if (userLanguage !== null) {
    return userLanguage.toUpperCase() as Language;
  }
  return defaultLanguage;
};
